'use strict';

import "../sass/app.scss";


window.$ = window.jQuery = $;

import 'jquery.easing';
import 'bootstrap';
import '@fancyapps/fancybox';
import objectFitImages from 'object-fit-images';
import 'waitMe/waitMe';

import CAjax from './include/ajax';
import CLib from "./include/functions";
import CIndex from './modules/index';
import CProjects from "./modules/projects";
import CServices from "./modules/services";
import CCompany from "./modules/company";

class CApp {
    constructor() {
        console.log('init app');

        if (this.checkBrowser() === false) {
            return false;
        }

        this.initModules();
        this.bindActions();
        this.adminPanelFix();

        $("body").removeClass("loading--mobile");
    }

    initModules() {
        window.f = new CLib();
        window.AjaxLib = new CAjax();
        window.App = this;

        let alias = $("html").attr("data-alias");

        if (alias === "index") {
            new CIndex();
        }

        if (alias === "proekty") {
            new CProjects();
        }

        if (alias === "uslugi") {
            new CServices();
        }

        if (alias === "o-kompanii") {
            new CCompany();
        }

        objectFitImages();
        this.checkAttention();
        this.fillMobileMenu();
    }

    adminPanelFix() {
        console.log('adminPanelFix');

        let $adminPanel = $("#admin-panel");

        if (!!$adminPanel.length) {
            $("#header").css({'margin-top': $adminPanel.outerHeight()}).addClass("fixed");
        }
    }

    bindActions() {
        this.bindFancyBox();
        this.toTopHandler();
        this.makePhonesClickable();
    }

    bindFancyBox() {
        $.fancybox.defaults.margin = [0, 0];
        $.fancybox.defaults.hash = false;
        $.fancybox.defaults.lang = 'ru';
        $.fancybox.defaults.i18n = {
            'ru': {
                CLOSE: 'Закрыть',
                NEXT: 'Далее',
                PREV: 'Назад',
                ERROR: 'Запрошенный элемент не удалось загрузить.',
                PLAY_START: 'Начать слайдшоу',
                PLAY_STOP: 'Пауза слайдшоу',
                FULL_SCREEN: 'На весь экран',
                THUMBS: 'Превью'
            }
        };

        $('[data-fancybox], .zoom').fancybox({});
    }


    openPopup(event, sender, type = 'inline') {
        event.preventDefault();
        let $sender = $(sender);

        let opts = {
            touch: {
                vertical: false, // Allow to drag content vertically
                momentum: false   // Continuous movement when panning
            },
            loop: false,
            gutter: 0,
            margin: [0, 0],
            infobar: true,
            keyboard: true,
            slideShow: false,
            fullScreen: {
                autoStart: false
            },
            iframe: {
                preload: false
            },
            thumbs: false,
            parentEl: 'body',
            defaultType: 'html',
            autoFocus: false,
            backFocus: false,
            trapFocus: false,
            lang: 'ru',
            i18n: {
                'ru': {
                    CLOSE: 'Закрыть',
                    NEXT: 'Далее',
                    PREV: 'Назад',
                    ERROR: 'Не удалось загрузить контент',
                    PLAY_START: 'Начать слайдшоу',
                    PLAY_STOP: 'Приостановить слайдшоу',
                    FULL_SCREEN: 'Полный экран',
                    THUMBS: 'Превью',
                    DOWNLOAD: 'Загрузить',
                    SHARE: 'Поделиться'
                }
            },
            buttons: [
                //'slideShow',
                //'fullScreen',
                //'thumbs',
                //'share',
                //'download',
                'close'
            ],
            youtube: {
                //controls : 0,
                //showinfo : 0
            },
            onActivate: () => {
                console.log('onActivate');
            },
            onComplete: (instance) => {
                console.log('onComplete');
                //console.log(instance);
            },
            onInit: (instance) => {
                console.log('onInit');
                //console.log(instance);
            },
            afterLoad: () => {
                console.log('afterLoad');
            },
            beforeLoad: () => {
                console.log('beforeLoad');
                //$("html").addClass("overflowed");
            },
            afterClose: () => {
                console.log('afterClose');
                //$("html").removeClass("overflowed");
            }
        };


        console.log(opts);
        //$.fancybox.defaults.margin = [0, 0];
        $.fancybox.open({
            src: $sender.attr("data-href"),
            type,
            opts
        });

    }

    toogleMenu(event, sender) {
        $(sender).toggleClass('opened');

        let $header = $("#header");
        let $menu = $header.find("#mobile-menu");

        if ($header.hasClass("opened")) {
            $header.removeClass("opened");
            $menu.find(".header-menu").removeClass("opened");
            $header.find(".burger").removeClass("opened");
            $("html").removeClass("inactive");
        } else {
            $header.addClass("opened");
            $menu.find(".header-menu").addClass("opened");
            $header.find(".burger").addClass("opened");
            $("html").addClass("inactive");
        }
    }

    fillMobileMenu() {
        console.log('fillMobileMenu');

        let $mobileMenu = $("#mobile-menu");
        if (!$mobileMenu.find(".header-menu").length) {
            $mobileMenu.html($("#header-menu").html());
        }
    }

    checkAttention() {
        let isAttentionAccepted = f.getCookie("USER_READED_ATTENTION");

        if (isAttentionAccepted !== '1') {
            $("#attention").addClass("opened");
        }
    }

    hideAttention(event, sender) {
        event.preventDefault();

        f.setCookie("USER_READED_ATTENTION", 1, 365);
        $("#attention").removeClass("opened");
    };

    toTopHandler() {
        let button = $('#up-button');

        $(window).on('scroll resize orientationchange', function () {
            if ($(this).scrollTop() > 300) {
                button.addClass('show');
            } else {
                button.removeClass('show');
            }
        });

        button.on('click', function () {
            f.scrollTo();
        });
    };

    checkBrowser() {
        let browser = this.getBrowserInfo();

        if (browser.name === "MSIE" && browser.version <= 10) {
            window.location.replace("/ie67/");
            return false;
        }

        if (browser.name === "Firefox" && browser.version <= 28) {
            window.location.replace("/ie67/");
            return false;
        }

        if (browser.name === "Chrome" && browser.version <= 32) {
            window.location.replace("/ie67/");
            return false;
        }

        if (browser.name === "Safari" && browser.version <= 6) {
            window.location.replace("/ie67/");
            return false;
        }

        if (browser.name === "Opera" && browser.version <= 20) {
            window.location.replace("/ie67/");
            return false;
        }

        return true;
    }

    getBrowserInfo() {
        let name = '';
        let version = 0;

        if (/Opera[\/\s](\d+\.\d+)/.test(navigator.userAgent)) {
            name = 'Opera';
        } else if (/MSIE (\d+\.\d+);/.test(navigator.userAgent)) {
            name = 'MSIE';
        } else if (/Navigator[\/\s](\d+\.\d+)/.test(navigator.userAgent)) {
            name = 'Netscape';
        } else if (/Chrome[\/\s](\d+\.\d+)/.test(navigator.userAgent)) {
            name = 'Chrome';
        } else if (/Safari[\/\s](\d+\.\d+)/.test(navigator.userAgent)) {
            name = 'Safari';
            /Version[\/\s](\d+\.\d+)/.test(navigator.userAgent);
            version = new Number(RegExp.$1);
        } else if (/Firefox[\/\s](\d+\.\d+)/.test(navigator.userAgent)) {
            name = 'Firefox';
        }
        if (version === 0) {
            version = parseFloat(new Number(RegExp.$1));
        }

        return {
            name,
            version
        };
    }

    makePhonesClickable() {

        if (!f.isMobile()) {
            return;
        }

        $(document).find(".phone").each((i, item) => {
            let $item = $(item);
            let text = $item.html();
            text = text.replace(text, '<a class="phone phone--link" href="tel:' + text + '">' + text + '</a>');
            $item.html(text);
        });

    };

}


$(window).bind("load.app", () => new CApp());



