'use strict';

import Macy from "macy";

export default class CCompany {

    constructor() {
        console.log('init company');

        try {
            Animator.initAboutAnimation();
            this.initGalleryBricks();
            this.initSertificatesBricks();
        } catch (e) {
            console.log(e);
        }
    }

    initSertificatesBricks() {
        console.log('initSertificatesBricks');

        let container = '.js-sertificates-bricks';

        if (!$(container).length) {
            return;
        }

        Macy({
            container,
            trueOrder: false,
            waitForImages: false,
            margin: {
                x: 28,
                y: 28,
            },
            columns: 4,
            breakAt: {
                1600: {
                    margin: {
                        x: 24,
                        y: 24,
                    },
                    columns: 4
                },
                1200: {
                    margin: {
                        x: 22,
                        y: 22,
                    },
                    columns: 4
                },
                992: {
                    margin: {
                        x: 18,
                        y: 18,
                    },
                    columns: 4
                },
                768: {
                    margin: {
                        x: 22,
                        y: 22,
                    },
                    columns: 3
                },
                576: {
                    margin: {
                        x: 20,
                        y: 20,
                    },
                    columns: 2
                },
                320: {
                    margin: {
                        x: 0,
                        y: 14,
                    },
                    columns: 1
                }
            }
        });
    }

    initGalleryBricks() {
        console.log('initGalleryBricks');

        let container = '.js-bricks';

        if (!$(container).length) {
            return;
        }

        Macy({
            container,
            trueOrder: false,
            waitForImages: false,
            margin: {
                x: 24,
                y: 24,
            },
            columns: 3,
            breakAt: {
                1600: {
                    margin: {
                        x: 22,
                        y: 22,
                    },
                    columns: 3
                },
                1200: {
                    margin: {
                        x: 18,
                        y: 18,
                    },
                    columns: 3
                },
                992: {
                    margin: {
                        x: 16,
                        y: 16,
                    },
                    columns: 3
                },
                768: {
                    margin: {
                        x: 14,
                        y: 14,
                    },
                    columns: 3
                },
                576: {
                    margin: {
                        x: 14,
                        y: 14,
                    },
                    columns: 2
                },
                320: {
                    margin: {
                        x: 0,
                        y: 14,
                    },
                    columns: 1
                }
            }
        });
    }
};
