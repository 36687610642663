'use strict';

import Swiper from 'swiper/dist/js/swiper';
import CSlice from '../include/slice';

export default class CIndex {

    constructor() {
        console.log('init index');

        //ширина и высота окна, при которой слайдер переключается в режим freeMode
        this.FREE_MODE_WIDTH = 1200;
        this.FREE_MODE_HEIGHT = 880;
        this.sliderId = '#slider-index';
        this.slider = null;

        this.initGallery();

        this.Slice = new CSlice($("#index-projects-slice"), 3);

        try {
            //попробуем запустить анимацию на слайде "о компании"
            //на случай, если слайдер не будет инициализирован
            //если слайдер будет инициализирован, то анимация
            //перезапустится в методе onSlideChange
            Animator.startAboutAnimation();
        } catch (e) {

        }

        //this.initIndexSlider();
        this.onResize();
        $(window).bind("resize.index orientationchange.index", () => this.onResize());

    }

    initIndexSlider() {
        console.log('init index slider');

        let indexSliderParams = {
            speed: 500,
            direction: 'vertical',
            effect: 'slide',
            hashNavigation: {
                replaceState: true,
            },
            simulateTouch: false,
            shortSwipes: true,
            longSwipes: true,
            followFinger: true,
            preventClicks: false,
            preventClicksPropagation: false,
            freeMode: false,
            freeModeMomentum: false,
            mousewheel: true,
            scrollbar: {
                el: '.swiper-scrollbar',
                draggable: true,
                hide: false
            },
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            }
        };

        $("html, .index").removeClass("height-auto");
        let slider = new Swiper(this.sliderId, indexSliderParams);
        this.slider = slider;
        this.onSlideChange();

        slider.on('slideChange', () => this.onSlideChange());
    };

    destroyIndexSlider() {
        this.slider.destroy(true, true);
        this.slider = null;
        $(".footer").removeClass("hided").removeClass("fixed");
    }

    onSlideChange() {
        let slides = this.slider.slides;
        let activeIndex = this.slider.activeIndex;
        //let previousIndex = slider.previousIndex;

        let $slide = $(slides[activeIndex]);

        //change header theme
        $("#header").attr("data-theme", $slide.attr('data-theme'));

        let slideAttrId = $slide.attr('id');

        if (slideAttrId === "slide-promo") {
            $(".header__logo").removeClass("hided");
        } else {
            $(".header__logo").addClass("hided");
        }

        if (slideAttrId === "slide-projects") {
            this.Slice.run();
        } else {
            this.Slice.pause();
        }

        if (slideAttrId === "slide-contacts") {
            setTimeout(function () {
                $(".footer").addClass("fixed").removeClass("hided");
            }, 400);

        } else {
            $(".footer").addClass("hided").removeClass("fixed");
        }

        try {
            if (slideAttrId === "slide-about") {
                Animator.startAboutAnimation();
            } else {
                Animator.stopAboutAnimation();
            }
        } catch (e) {

        }

        if (slideAttrId === "slide-map") {
            $("#index-map-cities").addClass("running");
        } else {
            $("#index-map-cities").removeClass("running");
        }
    };

    onResize() {
        console.log('onResize');

        let isSmallWindow = $(window).width() < this.FREE_MODE_WIDTH || $(window).height() < this.FREE_MODE_HEIGHT;

        if (!!$("#admin-panel").length) {
            isSmallWindow = true;
        }

        if (isSmallWindow === true && !!this.slider) {
            console.log('slider destor');
            this.destroyIndexSlider();
        } else if (isSmallWindow === false && !this.slider) {
            console.log('slider reinit');
            this.initIndexSlider();
        }

        if (!this.slider) {
            $("html, .index").addClass("height-auto");
            $(".header__logo").removeClass("hided");
        }
    }

    initGallery() {
        console.log('init gallery');

        let gallerySliderParams = {
            //loop: true,
            simulateTouch: true,
            shortSwipes: true,
            longSwipes: true,
            followFinger: true,
            slidesPerView: 1,
            spaceBetween: 0,
            watchOverflow: true,
            slideToClickedSlide: true,
            speed: 500, //должно совпадать со временем transition из css
            effect: 'slide', // "slide", "fade", "cube", "coverflow" or "flip",
            navigation: {
                nextEl: '.index-gallery__button--next',
                prevEl: '.index-gallery__button--prev',
                disabledClass: 'disabled'
            }
        };

        new Swiper("#slider-gallery", gallerySliderParams);
    }
};