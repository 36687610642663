'use strict';

import Swiper from 'swiper/dist/js/swiper';


export default class CProjects {

    constructor() {
        console.log('init projects');

        this.MIN_WIDTH = 992; //минимальная ширина, при которой инициализировать слайдеры

        this.sliders = [];

        this.onResize();
        this.initDetailSlider();

        $(window).bind("resize.projects orientationchange.projects", () => this.onResize());
    }


    initSliders() {
        console.log('init projects slider');

        let sliderParams = {
            speed: 500,
            autoHeight: true,
            effect: 'slide', // "slide", "fade", "cube", "coverflow" or "flip",
            navigation: {
                nextEl: '.index-gallery__button--next',
                prevEl: '.index-gallery__button--prev',
                disabledClass: 'disabled'
            },
            slidesPerView: 2,
            spaceBetween: 50
        };

        $(".projects__container").each((i, item) => {
            this.sliders.push(new Swiper(item, sliderParams));
        });

    }

    onResize() {
        console.log('onResize');

        if ($(window).width() < this.MIN_WIDTH) {
            for (let slider of this.sliders) {
                slider.destroy(true, true);
            }

            this.sliders = [];
        } else {
            if (!this.sliders.length) {
                console.log('slider reinit');
                this.initSliders();
            }
        }
    }

    initDetailSlider() {
        console.log('initDetailSlider');

        let sliderParams = {
            speed: 500,
            effect: 'fade', // "slide", "fade", "cube", "coverflow" or "flip",
            navigation: {
                nextEl: '.slider-button--next',
                prevEl: '.slider-button--prev',
                disabledClass: 'disabled'
            },
            slidesPerView: 1,
            spaceBetween: 0
        };

        new Swiper($("#project-detail-slider"), sliderParams);
    }
};
