'use strict';

export default class CLib {

    constructor() {
        console.log('init lib');
    }

    scrollTo(scrollTop = 0, speed = 400) {
        $('html, body').animate({scrollTop}, speed, 'easeInOutCubic');
    }

    escapeRegExChars(value) {
        return value.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&");
    }


    getEventNameByAlias(eventName, bTouch = false, bClick = true, bKey = true) {
        let eventNameReturn = '';

        if (bClick === true)
            eventNameReturn += 'click.' + eventName + ' ';
        if (bKey === true)
            eventNameReturn += 'keyup.' + eventName + ' ';
        if (bTouch === true)
            eventNameReturn += 'touchend.' + eventName + ' ';

        return $.trim(eventNameReturn);
    }

    isEmpty(value) {
        value = $.trim(value);
        return value === undefined || value === '' || value === null;
    }

    isExist($el) {
        return $el.length > 0;
    }

    isEmail(val) {
        let pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
        return pattern.test(val);
    }

    isMobile() {
        let ua = navigator.userAgent;
        if (ua.match(/Android/i) || ua.match(/webOS/i) || ua.match(/iPhone/i) || ua.match(/iPad/i) ||
            ua.match(/iPod/i) || ua.match(/BlackBerry/i) || ua.match(/Windows Phone/i))
            return true;
        return false;
    }

    isIE() {
        let ua = window.navigator.userAgent;
        let msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        let trident = ua.indexOf('Trident/');
        if (trident > 0) {
            let rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        let edge = ua.indexOf('Edge/');
        if (edge > 0) {
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        return false;
    }

    setCookie(name, value, days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
    }

    getCookie(name) {
        let nameEQ = encodeURIComponent(name) + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0)
                return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
    }

    makeId() {
        return Math.random().toString(36).substring(7);
    }

    getPathFromUrl(url) {
        return url.split("?")[0];
    }

    stripQueryStringAndHashFromPath(url) {
        return url.split("?")[0].split("#")[0];
    }

    today(plusDays = null) {
        let today = new Date();
        let date = new Date(today.getFullYear(), today.getMonth(), today.getDate());

        if (!!plusDays) {
            date.setDate(date.getDate() + plusDays);
        }

        return date;
    }

    isScrolledIntoView($elem) {
        let docViewTop = $(window).scrollTop();
        let docViewBottom = docViewTop + $(window).height();

        let elemTop = $elem.offset().top;
        let elemBottom = elemTop + $elem.height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

    animateCounter($elem, duration = 1500) {
        $elem.prop('Counter', 0).animate({
            Counter: $elem.text()
        }, {
            duration,
            easing: 'swing',
            queue: false,
            step: function (now) {
                $elem.text(Math.ceil(now));
            },
            complete: function () {
                $elem.removeClass("js-animation");
            }
        });
    }

}
