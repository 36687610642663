'use strict';
/* global f */

export default class CAjax {

    constructor() {
        console.log('init ajax');

        this.dflt = {
            url: '',
            type: 'POST',
            dataType: 'json',
            data: {},
            $container: $("body"),
            loadingClass: 'loading',
            onBeforeSend: null,
            onSuccess: null,
            onError: null,
            onFatal: null,
            onComplete: null,
            waitMeUse: true,
            waitMeOpts: {
                effect: 'bounce',
                text: '',
                bg: 'rgba(242,244,245, .5)',
                color: '#000'
            }
        };
    }


    send(opts = {}) {

        let dflt = this.dflt;

        let $container = !!opts.$container ? opts.$container : dflt.$container;
        let loadingClass = !!opts.loadingClass ? opts.loadingClass : dflt.loadingClass;
        let waitMeUse = !!opts.waitMeUse ? opts.waitMeUse : dflt.waitMeUse;
        let waitMeOpts = !!opts.waitMeOpts ? opts.waitMeOpts : dflt.waitMeOpts;

        let url = !!opts.url ? opts.url : dflt.url;
        let type = !!opts.type ? opts.type : dflt.type;
        let dataType = !!opts.dataType ? opts.dataType : dflt.dataType;
        let data = !!opts.data ? opts.data : dflt.data;

        let onBeforeSend = !!opts.onBeforeSend ? opts.onBeforeSend : dflt.onBeforeSend;
        let onSuccess = !!opts.onSuccess ? opts.onSuccess : dflt.onSuccess;
        let onError = !!opts.onError ? opts.onError : dflt.onError;
        let onFatal = !!opts.onFatal ? opts.onFatal : dflt.onFatal;
        let onComplete = !!opts.onComplete ? opts.onComplete : dflt.onComplete;

        let tryRedirect = (response) => {
            try {
                if (!!response.redirect) {
                    document.location.href = response.redirect;
                    return true;
                }
            } catch (e) {
            }
        };

        let tryAlert = (response) => {
            try {
                if (!!response.result.alert) {
                    App.showAlert(response.result.alert.title, response.result.alert.text);
                }
            } catch (e) {
            }
        };

        let beforeSend = (xhr) => {
            if (!!onBeforeSend) {
                onBeforeSend(xhr);
            } else {
                if ($container.hasClass(loadingClass)) {
                    xhr.abort();
                    return false;
                }

                $container.addClass(loadingClass);

                if (waitMeUse === true) {
                    $container.waitMe(waitMeOpts);
                }
            }
        };

        let success = (response) => {
            if (dataType === 'json') {
                tryRedirect(response);

                if (response.success === true) {
                    !!onSuccess ? onSuccess(response) : tryAlert(response);
                } else {
                    !!onError ? onError(response) : tryAlert(response);
                }
            } else {
                !!onSuccess ? onSuccess(response) : tryAlert(response);
            }
        };

        let error = (response) => {
            console.error('FATAL: ' + response.statusText + response.responseText);
            !!onFatal ? onFatal(response) : tryAlert(response);
        };

        let complete = () => {
            if (!!onComplete) {
                onComplete();
            }

            $container.removeClass('loading');

            if (waitMeUse === true) {
                $container.waitMe('hide');
            }
        };

        $.ajax({
            url,
            type,
            dataType,
            data,
            beforeSend,
            success,
            error,
            complete
        });

    };

    html(opts = {}) {
        opts.type = "POST";
        opts.dataType = "html";

        this.send(opts);
    };

};