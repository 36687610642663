'use strict';

import Swiper from 'swiper/dist/js/swiper';


export default class CServices {

    constructor() {
        console.log('init services');

        this.initDetailSlider();
    }

    initDetailSlider() {
        console.log('initDetailSlider');

        let sliderParams = {
            speed: 500,
            effect: 'fade', // "slide", "fade", "cube", "coverflow" or "flip",
            navigation: {
                nextEl: '.slider-button--next',
                prevEl: '.slider-button--prev',
                disabledClass: 'disabled'
            },
            slidesPerView: 1,
            spaceBetween: 0
        };

        new Swiper($("#service-detail-slider"), sliderParams);
    }
};
