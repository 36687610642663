'use strict';

require('jquery.easing');

export default class CSlice {

    constructor($slice, count = 3, duration = 2000, delay = 150) {
        this.$slice = $slice;
        this.count = count;
        this.duration = duration;
        this.delay = delay;
        this.easing = 'easeOutExpo';

        this.params = {
            figure: {
                widthStart: '100%',
                heightStart: '100%',
                duration: this.duration + this.delay * this.count
            }
        };

        this.init();
    }

    init() {
        if (!this.$slice.hasClass("ready")) {
            for (let i = 0; i < this.count; i++) {
                let $piece = $('<div class="slice__piece"></div>');
                $piece.css({width: 100 / this.count + '%'});

                this.$slice.append($piece);
            }

            this.$slice.addClass("ready");
        }
    }

    run() {
        let $figure = this.$slice.find("figure");
        let $pieces = this.$slice.find(".slice__piece");

        //остановим анимацию и сбросим css
        $pieces.each((i, item) => {
            $(item).stop().css({
                "top": 0
            });
        });

        $figure.stop().css({
            width: this.params.figure.widthStart,
            height: this.params.figure.heightStart
        });

        //запустим анимацию. каждый следующий элеменет запускаем с задержкой
        $pieces.each((i, item) => {
            setTimeout(function () {
                $(item).animate({
                    top: "100%",
                }, this.duration, this.easing);
            }, i * this.delay);
        });

        $figure.animate({
            width: "100%",
            height: "100%"
        }, this.params.figure.duration, () => {
            console.log('Animation complete');

            $figure.css({
                width: '',
                height: ''
            });
        });
    }

    pause() {
        let $figure = this.$slice.find("figure");
        let $pieces = this.$slice.find(".slice__piece");

        //скукоживаем все элементы плавно, чтобы не было скачков при скролле
        $pieces.each((i, item) => {
            $(item).stop().animate({
                top: 0,
            }, 500);
        });

        $figure.animate({
            width: this.params.figure.widthStart,
            height: this.params.figure.heightStart
        }, 100, () => {
            console.log('Animation complete');

            $figure.css({
                width: '',
                height: ''
            });
        });
    }
}
